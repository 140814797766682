import i18next from "i18next";
import { dedupingMixin } from "@polymer/polymer/lib/utils/mixin";

const i18nextMixin = function(superClass) {
   return class extends superClass {
      // eslint-disable-next-line no-useless-constructor
      constructor() {
         super();
      }

      static get properties() {
         return {};
      }

      t(key, option = {}) {
         // if((typeof option) === "string") {
         //    let op = {};
         //    try {
         //       op = JSON.parse(option);
         //    } catch (e) {
         //       op =  JSON.parse(option.replace(/'/g, "\""));
         //    }
         //    return i18next.t(key, op);
         // }
         if (window.props && window.props.i18next) return window.props.i18next.t(key, option);
         return i18next.t(key, option);
      }
   };
};

const mixinCommons = dedupingMixin(i18nextMixin);

export default mixinCommons;
